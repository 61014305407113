// 拿到当月的星期
function getFirstWeekDay (year, month) {
    const date = new Date(year, month - 1, 1);
    return date.getDay();
}
// 当月多少天
function getMonthDayCount (year, month) {
    const date = new Date(year, month, 0);
    return date.getDate();
}
// 上个月有几天
function getLastMonthRestDays (year, month) {
    const restDays = [];
    const days = getFirstWeekDay(year, month);
    let lastDate = getMonthDayCount(year, month - 1);
    while(restDays.length < days) {
        restDays.push(lastDate--);
    }
    return restDays.reverse();
}
// 拿到下个月有几天
function getNextMonthRestDays (year, month) {
    const lastMonthRestDayCount = getFirstWeekDay(year, month);
    const currentMonthDayCount = getMonthDayCount(year, month);
    const nextMonthRestDayCount = 42 - (lastMonthRestDayCount + currentMonthDayCount);
    let restDays = [];

    for (let i = 1; i <= nextMonthRestDayCount; i++) {
        restDays.push(i)
    }

    return restDays;
}
// 获取现在的年月日
function getDateInfo (timeStamp) {
    var date = timeStamp ? new Date(timeStamp) : new Date();

    return [
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
    ]
}
// 格式化日期 补0
function getFormatDate (year, month, date) {
    const dateArr = [year, month, date];

    for (let i = 1; i < dateArr.length; i++) {
        dateArr[i] < 10 && (dateArr[i] = '0' + dateArr[i]);
    };

    return dateArr.join('-');
}
// mac 电脑时间格式 2002/10/11
function getMacFormatDate (year, month, date) {
    const dateArr = [year, month, date];

    for (let i = 1; i < dateArr.length; i++) {
        dateArr[i] < 10 && (dateArr[i] = '0' + dateArr[i]);
    };

    return dateArr.join('/');
}
// 日期补0
function getFormatDay (date) {
    date < 10 && (date = '0' + date);
    return date;
}
// 获取上个月的年月
function getLastYearMonth (year, month) {
    month = month - 1;
    var date = new Date(year, month, 1);
    var lastMonth = date.getMonth() === 0 ? new Date(year - 1, 11) : new Date(year, date.getMonth() - 1);
    return {
        lastYear: lastMonth.getFullYear(),
        lastMonth: lastMonth.getMonth() + 1
    };
}
// 获取下个月的年月
function getNextYearMonth(year, month) {
    // 确保月份是从0（1月）到11（12月）的整数
    month = month - 1;

    // 创建一个Date对象，设置年月日为当前年月的第一天
    const date = new Date(year, month, 1);

    // 将日期设置为下个月的第一天
    date.setMonth(date.getMonth() + 1);

    // 获取下个月的年和月
    const nextYear = date.getFullYear();
    const nextMonth = date.getMonth() + 1; // 将月份从0（1月）转换为1（2月）到12（1月）

    return { nextYear: nextYear, nextMonth: nextMonth };
}

export {
    getFirstWeekDay,
    getMonthDayCount,
    getLastMonthRestDays,
    getNextMonthRestDays,
    getDateInfo,
    getFormatDate,
    getMacFormatDate,
    getLastYearMonth,
    getNextYearMonth,
    getFormatDay
}
